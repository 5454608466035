import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useUnitCompare } from '../../lib/providers/UnitCompareProvider'
import CompareIcon from '../icons/CompareIcon'
import TrashIcon from '../icons/TrashIcon'
import classes from './UnitCompareCard.module.css'

interface UnitCompareCardProps {
    isOpen: boolean
    parentTrigger: number
}

const UnitCompareCard = (props: UnitCompareCardProps) => {
    const {units, removeUnit} = useUnitCompare()

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (!props.isOpen) setIsActive(false)
    }, [props.isOpen])

    useEffect(() => {
        if (units.length === 0) setIsActive(false)
    }, [units.length])

    useEffect(() => {
        if (props.parentTrigger) setIsActive(true)
    }, [props.parentTrigger])

    return (
        <>
            <div
                onClick={() => setIsActive(prev => !prev)}
                className={`w-305px bg-main rounded text-white px-4 py-10px mb-3 flex items-center transform ${!props.isOpen ? 'opacity-0 translate-y-full pointer-events-none' : 'delay-300 opacity-100 translate-y-0 pointer-events-auto'} transition-all duration-150 ease-linear hover:bg-main-dark cursor-pointer`}
            >
                <CompareIcon width={30} height={30} className="mr-3 px-1" />
                <div className="h-14px border-l border-white mr-3" />
                <span className="font-semibold mr-auto">
                    {units.length} units selected
                </span>
                {
                    units.length > 0 &&
                    <FontAwesomeIcon icon={faAngleDown} className={`${isActive ? 'rotate-180' : ''} transform transition-all duration-150 ease-linear`} />
                }
            </div>
            <div
                className={`${classes.content} ${props.isOpen ? 'pointer-events-auto' : 'pointer-events-none'} ${isActive ? `${classes.active} px-3 py-4 mb-3` : 'pointer-events-none'} w-286px bg-white rounded grid grid-cols-1 gap-3 overflow-hidden transition-all duration-150 ease-linear ${classes['box-shadow']}`}
                data-amount={units.length}
            >
                {
                    units.map((unit, index) =>
                        <div
                            key={unit.id}
                            className="w-full flex items-center justify-center"
                        >
                            <img src={unit.img} alt="unit" className={`mr-10px rounded-sm overflow-hidden object-cover object-center flex-shrink-0 ${ classes.unit } `} />
                            <div className="flex flex-col items-start pr-3 mr-auto">
                                <span className="text-sm font-bold text-black-#3D3D3D mb-1 leading-tight">
                                    {unit.name}
                                </span>
                                <span className="text-2xs text-gray-#999A9B leading-tight">
                                    {unit.cluster_name}
                                </span>
                            </div>
                            <div className="w-8 h-8 rounded-full bg-red-#FFE0E2A8 flex-shrink-0 flex items-center justify-center hover:bg-red-#FFE0E2 transition-all duration-150 ease-linear cursor-pointer" onClick={() => removeUnit(index)}>
                                <TrashIcon className='text-red-#FF868D text-lg' />
                            </div>
                        </div>
                    )
                }
            </div>
            <a href='/compare-unit' className={`flex items-center justify-center bg-main w-286px ${props.isOpen ? 'pointer-events-auto' : 'pointer-events-none'} ${isActive ? 'h-47px mb-3' : 'h-0'} rounded text-white font-semibold hover:bg-main-dark transition-all duration-150 ease-linear cursor-pointer overflow-hidden`}>
                Compare
            </a>
        </>
    )
}

export default UnitCompareCard
