import React, { useEffect, useState } from 'react'
import AgentAxios from '../../utils/AgentAxios'
import { toastAxiosError } from '../../utils/Helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'

interface FloatingProfileProps {
    sidebar?: boolean
    logout: () => void
}

const FloatingProfile = (props: FloatingProfileProps) => {
    const [state, setState] = useState({
        name: '',
        email: '',
        affiliate: 0,
        affiliate_status: 0,
        photo_small_url: '',
        phone: '',
        type: 'user',
        is_profile_completed: false
    })

    useEffect(() => {
        renderStorage()
    }, [])

    // const handleIsFetch = () => {
    //     try {
    //         const isFetch = window.localStorage.getItem('fetch_profile')
    //         if (isFetch === '1') {
    //             fetchData()
    //         }
    //     } catch {
    //         // console.error('Cookies disabled')
    //     }
    // }

    const fetchData = () => {
        AgentAxios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/account-information`)
            .then(res => res.data)
            .then(res => {
                setState({
                    name: res.name,
                    email: res.email,
                    affiliate: res.account_information.is_affiliate,
                    affiliate_status: res.account_information.affiliate_status,
                    photo_small_url: res.photo_small_url,
                    phone: res.phone,
                    type: res.type,
                    is_profile_completed: res.is_profile_completed
                })
                localStorage.setItem('affiliate_status', res.account_information.affiliate_status)
                localStorage.setItem('username', res.name)
                localStorage.setItem('email', res.email)
                localStorage.setItem('phone', res.phone)
                localStorage.setItem('photo', res.photo_small_url)
                localStorage.setItem('is_profile_completed', res.is_profile_completed)
            })
            .catch(async (err) => {
                toastAxiosError(err)
            })
            .finally(() => {
                localStorage.removeItem('fetch_profile')
                // setIsLoading(false)
            })
    }

    const renderStorage = () => {
        try {
            const name = window.localStorage.getItem('username')
            const photo = window.localStorage.getItem('photo')
            const email = window.localStorage.getItem('email')
            const phone = window.localStorage.getItem('phone')
            const affiliate_status = window.localStorage.getItem('affiliate_status')
            const isFetch = window.localStorage.getItem('fetch_profile')
            const is_profile_completed = window.localStorage.getItem('is_profile_completed')

            if (affiliate_status || affiliate_status !== 'null') {
                if (name && photo && email && phone) {
                    setState(prev => ({
                        ...prev,
                        affiliate_status: Number(affiliate_status)
                    }))
                }
            }
            if (name && photo && email && phone) {
                setState(prev => ({
                    ...prev,
                    name: name,
                    email: email,
                    photo_small_url: photo,
                    phone: phone,
                    affiliate_status: Number(affiliate_status) || 0,
                    is_profile_completed: is_profile_completed === 'true' ? true : false
                }))
            }

            if (isFetch) {
                fetchData()
            }
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    return (
        <div className={`${!props.sidebar ? 'container relative' : ''}`}>
            <div className={`${!props.sidebar && 'fixed lg:mr-5'} shadow-lg`}
                style={{
                    width: !props.sidebar ? 400 : 'unset', top: 60, right: 0, zIndex: 99,
                    backgroundColor: '#ECEEEF', left: !props.sidebar ? '50%' : 'unset', transform: !props.sidebar ? 'translateX(calc(50% - 34px))' : 'unset'
                }}>
                <div
                    className="flex flex-col justify-between rounded-tl bg-cover bg-center bg-no-repeat"
                    style={{
                        height: 170,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        backgroundImage: `url("${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/abstract.png")`
                    }}
                >
                    <div className='flex items-center my-auto px-5'>
                        <img src={state.photo_small_url && state.photo_small_url !== 'null' ? state.photo_small_url : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/img_avatar.png`} className="object-cover object-center rounded-full flex-shrink-0 mr-5" style={{ width: 78, height: 78 }} alt="avatar" />
                        <div>
                            <div className='flex items-center'>
                                <p className='font-bold text-white text-lg mr-2'>{state.name}</p>
                                {
                                    state.affiliate_status === 2 &&
                                    <div
                                        className="w-auto px-2 py-1 bg-red-#FFB9BE text-red-#E31E26 text-2xs rounded font-bold flex items-center justify-center"
                                    >
                                        Affiliate Partner
                                    </div>
                                }
                            </div>
                            <p className='font-bold text-white text-xs mr-2'>{state.phone}</p>
                            <div className='flex items-center'>
                                <p className='font-bold text-white text-xs mr-2'>{state.email}</p>
                                {/* <img src={'/static/images/svg/warning.svg'} className="mr-5" /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='py-2 px-5' style={{ background: 'rgba(227, 30, 38, 0.56)' }}>
                    <div className='text-xs text-white'>
                        <span className='mr-1'>Your email address needs to be verified.</span>
                        <span className='font-bold underline cursor-pointer'>Verify Now</span>
                    </div>
                </div> */}
                </div>
                <div className='p-5 bg-white mb-2'>
                    <a href='/profile' className='mb-5 flex'>
                        <img src={'/static/images/svg/profile-red.svg'} className="mr-5" />
                        <div className='flex justify-between w-full items-center'>
                            <p className='text-lg text-black-#353535'>My Profile</p>
                            {
                                !state.is_profile_completed &&
                                <u className='text-xs text-main'>Complete your profile</u>
                            }
                        </div>
                    </a>
                    <a href='/wishlist' className='mb-5 flex items-center'>
                        <FontAwesomeIcon icon={faHeart} className='' style={{ marginRight: 21, color: '#E31E26', width: 21, height: 21 }} />
                        {/* <img src={'/static/images/svg/heart-red.svg'} className="mr-5" /> */}
                        <p className='text-lg text-black-#353535'>Wishlist</p>
                    </a>
                    <a href='/recentseen' className='flex'>
                        <img src={'/static/images/svg/time-red.svg'} className="mr-5" />
                        <p className='text-lg text-black-#353535'>Recent Property</p>
                    </a>
                </div>
                <div className='p-5 bg-white mb-2'>
                    <a href='/search' className=''>
                        <p className='text-lg text-black-#353535'>Search Property</p>
                    </a>
                </div>
                <div className='p-5 bg-white mb-2'>
                    {/* <div className='mb-5'>
                        <p className='text-lg text-black-#353535'>Contact Us</p>
                    </div> */}
                    {/* <div className='mb-5'>
                        <p className='text-lg text-black-#353535'>Privacy Policy</p>
                    </div> */}
                    {
                        state.type !== 'agent' &&
                        <div className=''>
                            <div className='flex justify-between w-full items-center'>
                                <a href='/affiliate-page' className='text-lg text-black-#353535'>Affiliate Partner</a>
                                {
                                    state.affiliate_status === 1 &&
                                    <div className='rounded-lg text-yellow-#FFB629 bg-yellow-#FEE9BF p-2 text-xs'>
                                        On Verification
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className='p-5 bg-white cursor-pointer' onClick={props.logout}>
                    <div className='flex'>
                        <img src={'/static/images/svg/logout.svg'} className="mr-5" />
                        <p className='text-lg text-black-#353535'>Logout</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloatingProfile
