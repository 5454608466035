import { ReactNode } from "react"

interface IProps {
    children: ReactNode
    fontSize?: string
    fontWeight?: string
    fontColor?: string
    variant?: 'outlined' | 'contained' | 'plain'
    size?: 'sm' | 'md' | 'lg' | string
    color?: 'main' | 'accent' | 'white'
    classes?: string
    styles?: Object
    onClick?: Function
    fullWidth?: boolean
    borderSize?: string
    borderColor?: string
    href?: string
    target?: string
    disabled?: boolean
    rounded?: string
    plainClasses?: string
    rel?: string
}

const Button = ({ children, fontSize, fontWeight, fontColor, variant, size, color, classes, styles, onClick, fullWidth, borderSize, borderColor, href, target, disabled, rounded, plainClasses, rel }: IProps) => {
    const colorStyle = () => {
        if (variant && variant === 'outlined') {
            return `${borderSize || 'border'} ${borderColor}`
        } else if (variant && variant === 'contained') {
            switch (color) {
                case 'main':
                    return 'bg-main text-white hover:bg-main-dark active:bg-main-dark'
                case 'accent':
                    return 'bg-accent rounded text-white'
                case 'white':
                    return 'bg-white rounded text-black-48'
                default:
                    return 'bg-main text-white hover:bg-main-dark active:bg-main-dark'
            }
        }
    }

    const baseStyle = (variant !== 'plain') ? `${rounded || 'rounded'} focus:shadow-outline` : plainClasses || 'hover:text-main active:text-main'

    const sizeStyle = () => {
        switch (size) {
            case 'sm':
                return ''
            case 'md':
                return 'py-1 px-3'
            case 'lg':
                return 'py-2 px-4 lg:px-5'
            default:
                return size || ''
        }
    }

    return (
        <>
            {
                href ?
                    <a
                        href={href}
                        target={target || undefined}
                        rel={rel || undefined}
                        className={`${fullWidth && 'w-full'} ${baseStyle} ${sizeStyle()} ${colorStyle()} ${classes} ${fontColor || 'text-white'} ${fontSize || 'text-base'} ${fontWeight || 'font-normal'} focus:outline-none`}
                        style={styles}
                    >
                        {children}
                    </a>
                    :
                    <button
                        className={`${fullWidth && 'w-full'} ${baseStyle} ${sizeStyle()} ${colorStyle()} ${classes} ${fontColor || 'text-white'} ${fontSize || 'text-base'} ${fontWeight || 'font-normal'} ${disabled ? 'cursor-not-allowed' : ''} focus:outline-none`}
                        onClick={() => onClick ? onClick() : undefined}
                        style={styles}
                        disabled={disabled}
                    >
                        {children}
                    </button>
            }
        </>
    )
}

export default Button