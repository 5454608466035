import AutoSuggest from "../forms/AutoSuggest";

import { generateUrl } from "../../utils/Helper";
import classes from './Sidebar.module.css'
import FloatingProfile from "./FloatingProfile";

interface IProps {
    langCode: string
    lang: any
    show: boolean
    white?: boolean
    onClickClose: Function
    sidebarZIndex?: string
    isLoggedIn: boolean
    user_slug?: string
    logout: () => void
}

export default function Filter({ langCode, lang, white, show, onClickClose, sidebarZIndex, isLoggedIn, user_slug, logout }: IProps) {
    return (
        <div className={`${white ? `${sidebarZIndex || 'z-30'} z-30 bg-white text-black-48` : `${sidebarZIndex || 'z-10'}z-10 bg-main text-white pt-16 p-5`} fixed top-0 left-0 h-full w-full text-base overflow-y-auto pb-20 transform ${show ? '' : 'translate-x-full'} ease-in-out duration-300 block lg:hidden`}>
            <div className="flex flex-col pt-2">
                {
                    white ?
                        <div className="relative mb-4 flex flex-row justify-between">
                            <a href={process.env.NEXT_PUBLIC_MAINSITE_URL} className={`cursor-pointer lg:mr-4 pl-4`}>
                                <img src={`/static/images/svg/sinarmas_logo.svg`} alt="logo" className={`${classes.logo}`} />
                                {/* <img src={isTransparent || isBlue ? `${process.env.NEXT_PUBLIC_MAINSITE_URL}/assets/images/svg/sinarmas_logo.svg` : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/assets/images/svg/logo.svg`} alt="logo" style={{ width: "175px", height: "38px" }} /> */}
                            </a>
                            <button
                                title="Option User"
                                id="button_option_user"
                                className={`lg:hidden hamburger hamburger--slider is-active gray pr-4 py-0`}
                                onClick={() => onClickClose()}
                                type="button"
                            >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                        : null
                }

                <FloatingProfile sidebar logout={logout} />
            </div>
        </div>
    )


}