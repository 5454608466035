import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DOMPurify from 'isomorphic-dompurify'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { _getCookie, _setCookie } from '../../utils/Cookie'
import DefaultAxios from '../../utils/DefaultAxios'
import { unauthorizedErrorHandler, useStorage } from '../../utils/Helper'
import Button from '../forms/Button'
import OutlinedInput from '../forms/OutlinedInput'

import classes from './FeedbackCustomer.module.css'

interface FeedbackCustomerProps {
    feedbackMobileExtraMargin?: boolean
    isOpen: boolean
}


const FeedbackCustomer = (props: FeedbackCustomerProps) => {
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isError, setIsError] = useState(false)
    const [values, setValues] = useState(initialState)

    const [isDone, setIsDone] = useState(true)
    const [isDoneJustNow, setIsDoneJustNow] = useState(false) // to handle fade animation

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setValues(prev => ({
            ...prev,
            notes: value,
        }))
    }

    const submit = () => {
        let isValid = true

        Object.entries(values).forEach(([key, value]) => {
            if (key !== 'notes' && !value) isValid = false
        })

        if (isValid) {
            setIsError(false)

            setIsLoading(true)

            const data = {
                ...values,
                notes: values.notes ? DOMPurify.sanitize(values.notes) : null
            }

            DefaultAxios.post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/website-feedback`, data)
                .then(res => res.data)
                .then(data => {
                    toast("Your review has been submitted", {
                        closeButton: false,
                        type: 'success',
                        autoClose: 3000,
                        // position: 'top-right',
                        // className: 'rounded mb-10 xs:px-2 md:px-8 text-center mx-auto',
                        style: { color: '#fff', width: 'unset' }
                    })
                    setIsActive(false)
                    setValues(initialState)

                    useStorage(() => {
                        _setCookie('customer-feedback-submitted', '1')
                    })
                    
                    setTimeout(() => {
                        setIsDoneJustNow(true)
                    }, 100);
                })
                .catch(unauthorizedErrorHandler)
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setIsError(true)
        }
    }

    useEffect(() => {
        useStorage(() => {
            const isFeedbackDone = _getCookie('customer-feedback-submitted')
    
            setIsDone(!!isFeedbackDone)
        }, () => {
            setIsDone(false)
        })
    }, [])

    useEffect(() => {
        if (isDoneJustNow) { // after animation finished, remove the component
            setTimeout(() => {
                setIsDone(true)
            }, 300);
        }
    }, [isDoneJustNow])

    return (
        !isDone ?
        <>
            <div className={`${props.isOpen ? 'pointer-events-auto' : 'pointer-events-none'} bg-white transform border shadow rounded ${classes.container} ${isActive ? classes.active : ''} ${isActive ? 'opacity-100 pointer-events-auto py-8 md:py-4 px-8 md:px-5 md:mb-5' : 'opacity-0 pointer-events-none'} transition-all duration-200 ease-linear`}>
                <FontAwesomeIcon
                    icon={faTimes}
                    width="0"
                    onClick={() => setIsActive(false)}
                    size='lg'
                    className="absolute right-0 mr-6 mt-2 cursor-pointer md:hidden"
                />
                <p className='text-2xl font-bold text-black-#3D3D3D mb-6 md:mb-3.5 leading-tight'>
                    Tell us how do you like this website?
                </p>
                <hr className='border-t border-gray-#DBDBDB mb-6 md:mb-18px' />
                <QuestionBox
                    text='E-Catalog is very easy to use*'
                    value={values.q1}
                    setValue={value => setValues(prev => ({
                        ...prev,
                        q1: value,
                    }))}
                    isError={isError && values.q1 === 0}
                />
                <QuestionBox
                    text='The appearance (design) looks good to me*'
                    value={values.q2}
                    setValue={value => setValues(prev => ({
                        ...prev,
                        q2: value,
                    }))}
                    isError={isError && values.q2 === 0}
                />
                <QuestionBox
                    text='E-Catalog gives many property options*'
                    value={values.q3}
                    setValue={value => setValues(prev => ({
                        ...prev,
                        q3: value,
                    }))}
                    isError={isError && values.q3 === 0}
                />
                <QuestionBox
                    text='The discounts and promotions on E-Catalog are very attractive*'
                    value={values.q4}
                    setValue={value => setValues(prev => ({
                        ...prev,
                        q4: value,
                    }))}
                    isError={isError && values.q4 === 0}
                />
                <QuestionBox
                    text='Sales agent responded quickly to my enquiries*'
                    value={values.q5}
                    setValue={value => setValues(prev => ({
                        ...prev,
                        q5: value,
                    }))}
                    isError={isError && values.q5 === 0}
                />
                <p className='text-13px md:text-xs mb-2'>
                    Can you elaborate more? (Optional)
                </p>
                <OutlinedInput
                    placeholder="Write your review..."
                    name="notes"
                    value={values.notes}
                    onChange={onChange}
                    textArea
                    fontSize='text-13px md:text-11px'
                    textAreaProps={{
                        rows: 4
                    }}
                />
                <div className="mb-5" />
                {
                    isError &&
                    <div className='flex items-center mb-1.5'>
                        <img src="/static/images/svg/error.svg" title='error' alt="error" className='text-base mr-1' />
                        <span className='text-2xs font-bold text-red-#CE2029'>
                            Please fill in all the required fields.
                        </span>
                    </div>
                }
                <Button
                    color='main'
                    variant='contained'
                    fullWidth
                    size='py-2 px-4 md:py-1 md:px-3'
                    onClick={submit}
                    disabled={isLoading}
                >
                    { isLoading ? 'Loading...' : 'Submit' }
                </Button>
            </div>
            <button
                onClick={() => setIsActive(prev => !prev)}
                className={`${classes.button} ${isActive ? classes.active : ''} mb-5 p-3 transform ${props.feedbackMobileExtraMargin ? '' : ''}  ${isActive ? 'rounded-br-md' : 'rounded-tr-md'} ${(isDoneJustNow || !props.isOpen) ? 'delay-150 opacity-0 translate-y-full pointer-events-none' : 'delay-150 opacity-100 translate-y-0 pointer-events-auto'} rounded-l-md bg-main hover:bg-main-dark flex items-center transition-all duration-150 ease-linear focus:outline-none`}
            >
                {
                    isActive ?
                        <FontAwesomeIcon icon={faTimes} width="0" className={`text-white ${classes.icon}`} />
                        :
                        <>
                            <img src="/static/images/svg/feedback.svg" title="feedback" alt="feedback" className={`text-white ${classes.icon}`} />
                            <span className='hidden md:inline text-white ml-1.5'>
                                Write a review
                            </span>
                        </>
                }
            </button>
        </>
        :
        null
    )
}

interface QuestionBoxProps {
    text: string
    value: number
    isError: boolean
    setValue: (value: number) => void
}

const QuestionBox = (props: QuestionBoxProps) => {
    return (
        <>
            <p className='text-13px md:text-xs mb-2'>
                {props.text}
            </p>
            <div className={`flex items-center justify-between py-1 px-2 mb-5 border ${props.isError ? 'border-main' : 'border-gray-#DBDBDD'} rounded transition-colors duration-150 ease-linear`}>
                <span className='text-12px md:text-11px text-gray-#8F8F8F text-center'>
                    Poor
                </span>
                <button
                    className={`text-12px md:text-11px w-25px md:w-23px h-25px md:h-23px flex items-center justify-center transition-all duration-150 ease-linear rounded-sm ${props.value === 1 ? 'bg-main text-white' : 'bg-white text-black-#3D3D3D'} focus:outline-none`}
                    onClick={() => props.setValue(1)}
                >
                    1
                </button>
                <button
                    className={`text-12px md:text-11px w-25px md:w-23px h-25px md:h-23px flex items-center justify-center transition-all duration-150 ease-linear rounded-sm ${props.value === 2 ? 'bg-main text-white' : 'bg-white text-black-#3D3D3D'} focus:outline-none`}
                    onClick={() => props.setValue(2)}
                >
                    2
                </button>
                <button
                    className={`text-12px md:text-11px w-25px md:w-23px h-25px md:h-23px flex items-center justify-center transition-all duration-150 ease-linear rounded-sm ${props.value === 3 ? 'bg-main text-white' : 'bg-white text-black-#3D3D3D'} focus:outline-none`}
                    onClick={() => props.setValue(3)}
                >
                    3
                </button>
                <button
                    className={`text-12px md:text-11px w-25px md:w-23px h-25px md:h-23px flex items-center justify-center transition-all duration-150 ease-linear rounded-sm ${props.value === 4 ? 'bg-main text-white' : 'bg-white text-black-#3D3D3D'} focus:outline-none`}
                    onClick={() => props.setValue(4)}
                >
                    4
                </button>
                <button
                    className={`text-12px md:text-11px w-25px md:w-23px h-25px md:h-23px flex items-center justify-center transition-all duration-150 ease-linear rounded-sm ${props.value === 5 ? 'bg-main text-white' : 'bg-white text-black-#3D3D3D'} focus:outline-none`}
                    onClick={() => props.setValue(5)}
                >
                    5
                </button>
                <span className='text-12px md:text-11px text-gray-#8F8F8F text-center'>
                    Excellent
                </span>
            </div>
        </>
    )
}

export default FeedbackCustomer

const initialState = {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
    notes: '',
}