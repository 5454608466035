import ReactAutoSuggest from 'react-autosuggest'
import { useState, useCallback } from 'react'
import Axios from 'axios'

import _ from 'lodash'
import React from 'react'
import { escapeRegex } from '../../utils/Helper'

interface ISuggestion {
    title: string
    locations: ILocation[]
}

interface ILocation {
    ltext: string // name
    lt: string // type
    lid: number // id
    slug: string | null
    district: string | null // buat apartemen
    area: string | null // buat district
    location?: string
}

const AutoSuggest = () => {
    const [initialSuggestions, setInitialSuggestions] = useState<ISuggestion[]>([])
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([])
    const [state, setState] = useState({
        search: ''
    })

    // useEffect(() => {
    //     fetchSearchList()
    // }, [])

    const fetchSearchList = (query?: string) => {
        Axios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/search-autocomplete?query=${query || ''}`)
            .then(res => res.data)
            .then(res => {
                const cities = res.cities ? res.cities.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const clusters = res.clusters ? res.clusters.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const districts = res.districts ? res.districts.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const projects = res.projects ? res.projects.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const provinces = res.provinces ? res.provinces.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []
                
                const output = [
                    {
                        title: 'Cities',
                        locations: cities
                    },
                    {
                        title: 'Clusters',
                        locations: clusters
                    },
                    {
                        title: 'Districts',
                        locations: districts
                    },
                    {
                        title: 'Projects',
                        locations: projects
                    },
                    {
                        title: 'Provinces',
                        locations: provinces
                    },
                ].filter(loc => loc.locations.length > 0)

                setInitialSuggestions(output)
                setSuggestions(output)
                // setSuggestions(output.filter(loc => Number(state.tp) === 2 ? loc.title !== lang.apartment : true))
            })
    }

    const debouncedFetchList = useCallback(_.debounce(fetchSearchList, 300), [])

    // Handle ketika fetch dibutuhkan
    const onSuggestionsFetchRequested = ({ value, reason }: any) => {
        switch (reason) {
            case 'input-changed':
                const inputValue = value.trim().toLowerCase()
                const escapedValue = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                if (escapedValue.length >= 2) {
                    debouncedFetchList(escapedValue)
                } else if (suggestions.length > 0) {
                    setSuggestions([])
                }
                break
            case 'input-focused':
                if (state.search && state.search.length >= 2) {
                    setSuggestions(initialSuggestions)
                } else if (suggestions.length > 0) {
                    setSuggestions([])
                }
                break
            case 'escape-pressed':
                debouncedFetchList()
                break
        }
    }

    // Handle ketika fetch diclear
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    // Fungsi untuk menentukan cara mendapatkan value dari suggestion
    const getSuggestionValue = (suggestion: ILocation) => suggestion.ltext

    // Handle change
    const handleLocationChange = (e: any, { newValue }: any) => {
        setState(prev => ({
            ...prev,
            search: newValue
        }))
    }

    const renderLabel = (text: string) => {
        if (state.search) {
            const regex = new RegExp(`${escapeRegex(state.search)}`, 'gi')
            
            let retArr = text.replace(regex, (str) => {
                return `:${str}:`
            }).split(':')
            
            const length = retArr.length
            
            if (length > 1) {
                return (
                    <p>
                        {
                            retArr.map((val, index) => {
                                if (regex.test(val)) {
                                    return <strong className="font-extrabold" key={index}>{val}</strong>
                                } else {
                                    return <React.Fragment key={index}>{val}</React.Fragment>
                                }
                            })
                        }
                    </p>
                )

            } else {
                return <p>{text}</p>
            }
        } else {
            return <p>{text}</p>
        }
    }

    // Render suggestion
    const renderSuggestion = (suggestion: ILocation) => {
        return (
            <div className="text-sm">
                { renderLabel(suggestion.ltext) }
                {
                    suggestion.location ?
                    <p className="text-xs text-gray-ab leading-tight">{`${suggestion.location}`}</p>
                    : null
                }
            </div>
        )
    }

    // Render title (Apartemen | Area)
    const renderSectionTitle = (section: ISuggestion) => {
        return (
            <strong>
                {section.title}
            </strong>
        )
    }

    // Mendaparkan daftar suggestion dari tiap section (Apartemen | Area)
    const getSectionSuggestions = (section: ISuggestion) => section.locations

    

    // Handle suggestion yang terpilih
    const onSuggestionSelected = (e: any, { suggestion }: any) => {
        if (suggestion.redirect) {
            window.location.href = suggestion.redirect
        }
    }

    // Input props untuk AutoSuggest
    const inputProps = {
        placeholder: 'Search...',
        value: state.search,
        onChange: handleLocationChange,
        className: "w-full text-sm outline-none bg-white border border-gray py-2 px-3 hover:shadow active:border-main focus:border-main",
        style: { width: 250 }
    }

    return (
        <div className="relative search-autosuggest navbar-autosuggest">
            <ReactAutoSuggest
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
                // shouldRenderSuggestions={() => true}
            />
        </div>
    )
}

export default AutoSuggest