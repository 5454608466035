import lang_id from './lang/id.json'
import lang_en from './lang//en.json'
import { IStringObject } from './Interfaces';
import DefaultAxios from './DefaultAxios';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { _getCookie, _setCookie } from './Cookie';

declare global {
    interface Window {
        dataLayer: any;
    }
}

export function deleteUniqueCode() {
    console.log('lets delete')
    localStorage.removeItem('rc');
    document.cookie = 'rc_cookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getLangCode(pathName: string) {
    const match = pathName.match(/^\/(\w{2})\//);
    if (match && match[1]) {
        return match[1];
    }
    return '';
}

export function getLanguage(lang_code: string) {
    if (lang_code && lang_code !== '') {
        const lang = lang_code === 'en' ? lang_en : lang_id
        return lang
    }
    return lang_id
}

export function parseLanguage(lang: string, key: string, replace: string) {
    return lang.replace(key, replace)
}

export function generateUrl(lang_code: string, url: string) {
    // NOTE: currently set for 1 localization only
    return `${process.env.NEXT_PUBLIC_MAINSITE_URL}${url}`
    // if (lang_code === 'en') {
    //     return `${process.env.NEXT_PUBLIC_MAINSITE_URL}/en${url}`
    // } else {
    // }
}

export function isAreaJakarta(area: string) {
    return area.toLowerCase().includes('jakarta')
}

export function getLangJakarta(area: string) {
    let arr = area.split(' ')
    arr = arr.map(str => {
        return str.toLowerCase()
    })
    return arr.join('-')
}

export function getAreaLang(lang: any, area: string) {
    if (area.toLowerCase().includes('jakarta')) {
        let arr = area.split(' ')
        arr = arr.map(str => {
            return str.toLowerCase()
        })
        const key = arr.join('-')
        return lang[key]
    } else {
        return area
    }
}

export function convertIndonesiaPhoneNumber(number: string) {
    const first_two_character = number.substring(0, 2);

    if (first_two_character === "08") {
        number = number.replace(/08/g, "628");
    }

    return number.replace(/[^\d]/, '');
}

export function convertNumberFormat(number_to_convert: string) {
    let converted_number = number_to_convert.toString();
    converted_number = converted_number.replace('.00', '');
    let number = converted_number.replace(/[^\d]/g, '');
    let num = number.replace(/,/gi, "");
    let num2 = num.split(/(?=(?:\d{3})+$)/).join(".");

    if (num2 === "0") {
        return "";
    }

    return num2;
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export function isMobile() {
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
        return true;
    } else {
        return false;
    }
}

export function isLg() {
    return window.matchMedia("only screen and (min-width: 1024px)").matches
}

export function getCurrentBreakpoint() {
    if (window.matchMedia("only screen and (min-width: 1600px)").matches) {
        return '2xl'
    }
    if (window.matchMedia("only screen and (min-width: 1170px)").matches) {
        return 'xl'
    }
    if (window.matchMedia("only screen and (min-width: 1024px)").matches) {
        return 'lg'
    }
    if (window.matchMedia("only screen and (min-width: 768px)").matches) {
        return 'md'
    }
    if (window.matchMedia("only screen and (min-width: 640px)").matches) {
        return 'sm'
    }
    return 'xs'
}

export function removeDecimal(number: string | undefined) {
    if (typeof number === 'undefined') {
        return "";
    } else {
        const exploded_number = number.split(".");
        return exploded_number[0];
    }
}

export function convertNumber(number: string, prefix?: string, with_decimal?: boolean) {
    let numberString;
    let decimal;

    //With Decimal
    if (with_decimal) {
        number = Number(number).toFixed(2);

        const exploded_number = number.split(".");
        numberString = parseInt(exploded_number[0].replace(/[^,\d]/g, '')).toString();
        decimal = typeof exploded_number[1] !== 'undefined' ? exploded_number[1] : "";
    } else {
        numberString = parseInt(number.replace(/[^,\d]/g, '')).toString();
    }

    const split = numberString.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah = rupiah + separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    let output = prefix ? prefix : ''

    if (with_decimal) {
        return rupiah ? `${output}${rupiah},${decimal}` : '';
    } else {
        return rupiah ? output + rupiah : ''
    }
}


export function convertPercentangeWithDecimal(number: string, max_number?: number) {
    if (typeof max_number === 'undefined') {
        max_number = 100;
    }

    if (number === "") {
        return "";
    }

    if ((number.match(/[.]/g) || []).length === 1) {
        if (number[number.length - 1] === '.') {
            const max_data = number.replace('.', '');

            if (parseInt(max_data) >= max_number) {
                return max_number;
            }
            return number;
        } else {
            const number_splitted = number.split('.');

            if (number_splitted[1].length === 1) {
                return parseFloat(number).toFixed(1);
            } else if (number_splitted[1].length === 2) {
                return parseFloat(number).toFixed(2);
            } else {
                return false;
            }
        }
    } else if ((number.match(/[.]/g) || []).length > 1) {
        return false;
    }

    if (isNaN(parseFloat(number))) {
        return false;
    } else {
        let new_value = parseFloat(number);
        new_value = new_value > max_number ? max_number : new_value;
        return new_value;
    }
}

export function inputNumber(number: string) {
    const numberString = number.replace(/[^,\d]/g, '').toString()
    return numberString ? numberString : ''
}

export function inputDecimal(number: string) {
    const numberString = inputNumber(number).replace(',', '.')
    return numberString ? numberString : ''
}

export function inputText(text: string) {
    const textString = text.replace(/[^a-zA-Z0-9 ]/g, '').toString()
    return textString ? textString : ''
}

export function getMonth(month: number, shortHand?: boolean) {
    let months: string[] = []
    if (shortHand) {
        months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
    } else {
        months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    return months[month]
}

export function censorWords(words: string) {
    let arrayOfWords = words.split(' ')
    arrayOfWords = arrayOfWords.map((word) => {
        return word[0] + '*'.repeat(word.length)
    })
    return arrayOfWords.join(' ')
}

export function currencyToNumber(currency: string) {
    return currency.replace(/[^0-9]/g, '')
}

export function parseQueryString(url: string) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj: any = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)![1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
}

export function getNumberText(num: string | number, lang: any) {
    if (typeof num === 'string') {
        num = parseInt(num)
    }
    if (num >= 1000000000) {
        let value = (num / 1000000000).toFixed(1).toString()
        const value_array = value.split('.')
        if (value_array[1] && value_array[1] === '0') {
            value = value_array[0]
        }
        return value + ' ' + lang.billion
    } else if (num >= 1000000) {
        let value = (num / 1000000).toFixed(1).toString()
        const value_array = value.split('.')
        if (value_array[1] && value_array[1] === '0') {
            value = value_array[0]
        }
        return value + ' ' + lang.million
    } else {
        return convertNumber(num.toString())
    }
}

export const renderDate = (
    date: Date | string,
    withDate: boolean, // render date
    withTime: boolean, // render time
    onlyMonthYear?: boolean, // is only render month and year for the date
    shortHand?: boolean, // is month using shorthand
    numberOnly?: boolean, // only render using number (not using month name)
    dateSep?: string, // separator for date format
    dateTimeSep?: string, // separator between date and time
    is24Hour?: boolean, // is time using 24 hour format
) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    let outputText = ''
    if (withDate) {
        if (numberOnly) {
            if (!onlyMonthYear) outputText += ((date.getDate() < 10) ? '0' + date.getDate().toString() : date.getDate()) + (dateSep || ' ')
            outputText += ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1))
                + (dateSep || '')
                + date.getFullYear()
        } else {
            if (!onlyMonthYear) outputText += ((date.getDate() < 10) ? '0' + date.getDate().toString() : date.getDate()) + (dateSep || ' ')
            if (numberOnly) {
                outputText += ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1)) + (dateSep || ' ')
            } else {
                outputText += getMonth(date.getMonth(), shortHand) + (dateSep || ' ')
            }
            outputText += date.getFullYear()
        }
    }
    if (withTime) {
        if (outputText !== '') {
            outputText += (dateTimeSep || ' at ')
        }
        if (is24Hour) {
            outputText += ((date.getHours() < 10) ? '0' + date.getHours().toString() : date.getHours())
                + ':'
                + ((date.getMinutes() < 10) ? '0' + date.getMinutes().toString() : date.getMinutes())
                + ':'
                + ((date.getSeconds() < 10) ? '0' + date.getSeconds().toString() : date.getSeconds())
        } else {
            outputText += ((date.getHours() > 12) ? date.getHours() - 12 : date.getHours())
                + ':'
                + ((date.getMinutes() < 10) ? '0' + date.getMinutes().toString() : date.getMinutes())
                + ' '
                + (date.getHours() > 12 ? 'PM' : 'AM')
        }
    }
    return outputText
}

export function generateSlug(text: string, sepSource: string, sepOutput: string) {
    let arrText = text.split(sepSource)
    arrText = arrText.map(txt => txt.toLowerCase())
    return arrText.join(sepOutput)
}

export function parseSlug(text: string, sepSource: string = '-') {
    let arrText = text.split(sepSource)
    arrText = arrText.map(txt => txt[0].toUpperCase() + txt.slice(1))
    const output: string = arrText.join(' ')
    return output
}

export function resizeAndResetOrientationImage(file: File) {
    return new Promise<File>((resolve, reject) => {
        getOrientation(file, (orientation: any) => {
            resetOrientation(URL.createObjectURL(file), orientation, (newImg: any) => {
                let img = document.createElement("img");
                let reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target) {
                        img.src = e.target.result as string;
                        img.onload = () => {
                            let canvas = document.createElement("canvas");
                            let ctx = canvas.getContext("2d");
                            if (ctx) {
                                ctx.drawImage(img, 0, 0);
                                let MAX_WIDTH = 800;
                                let MAX_HEIGHT = 800;
                                let width = img.width;
                                let height = img.height;
                                if (width > height) {
                                    if (width > MAX_WIDTH) {
                                        height *= MAX_WIDTH / width;
                                        width = MAX_WIDTH;
                                    }
                                } else {
                                    if (height > MAX_HEIGHT) {
                                        width *= MAX_HEIGHT / height;
                                        height = MAX_HEIGHT;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                ctx.drawImage(img, 0, 0, width, height);
                                let dataurl = canvas.toDataURL("image/png");
                                let [mimeType] = dataurl.replace('data:', '').split(';');
                                resolve(urlToFile(dataurl, file.name, mimeType));
                            }
                        };
                        img.onerror = function () {
                            alert('Invalid file type: ' + file.type);
                        };
                    }
                }
                reader.readAsDataURL(newImg);
            })
        })
    })

}
export function getOrientation(file: any, callback: any) {
    let reader = new FileReader();

    reader.onload = (event) => {
        var target = event.target;
        if (target) {
            var view = new DataView(target.result as any);

            if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);

            var length = view.byteLength,
                offset = 2;

            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return callback(-1);
                    }
                    var little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;

                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                }
                else if ((marker & 0xFF00) !== 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        }
    }

    reader.readAsArrayBuffer(file);
}

export function resetOrientation(srcBase64: any, srcOrientation: number, callback: any) {
    var img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

        if (ctx) {
            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            // transform context before drawing image
            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            // draw image
            ctx.drawImage(img, 0, 0);

            // export blob
            canvas.toBlob((blob: any) => {
                callback(blob);
            })
        }
    };

    img.src = srcBase64;
}

export function urlToFile(url: string, filename: string, mimeType: string) {
    return (
        fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

export const setCookie = (name: string, value: string, days: number) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; SameSite=strict; path=/";
}

export const copyToClipboard = (content: string) => {
    // Create a dummy input to copy the string array inside it
    let dummy = document.createElement("input") as HTMLInputElement

    // Add it to the document
    document.body.appendChild(dummy);

    // Output the array into it
    dummy.value = content;

    // Select it
    dummy.select();

    // Copy its contents
    document.execCommand("copy");

    // Remove it as its not needed anymore
    document.body.removeChild(dummy);
}

export function slugToText(slug: string) {
    let slugArr = slug.split('-')
    slugArr = slugArr.map(str => str[0].toUpperCase() + str.slice(1))
    return slugArr.length > 1 ? slugArr.join(' ') : slugArr[0]
}

/**
 * Get all query params in current url
 * @param callback
 */
export const getUrlQueryParams = (callback: (queryParams: IStringObject) => void) => {
    if (window) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1))
        let sURLVariables = sPageURL.split('&')
        let sParameterName
        const queryParams: IStringObject = {}

        for (let i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=')
            if (sParameterName[1] !== '') {
                queryParams[sParameterName[0]] = sParameterName[1]
            }
        }

        callback(queryParams)
    }
}

/**
 * Shallowly compare two objects with identical properties
 * @param a first object to be compared
 * @param b object to be compared
 * @param count starting count
 * @param spesificKeys spesific keys that want to be compared
 */
export function countDifferenceShallow(a: any, b: any, count: number, spesificKeys?: string[]): number {
    if (!!spesificKeys || (!!a && typeof a === 'object')) {
        let keys = spesificKeys || Object.keys(a)

        keys.forEach(key => {
            if (typeof a[key] === 'object') {
                if (a[key] instanceof Array) {
                    a[key].forEach((val: any, idx: number) => {
                        if (val !== b[key][idx]) count = count + 1
                    })
                } else {
                    count = count + countDifferenceShallow(a[key], b[key], count)
                }
            } else {
                if (a[key] !== b[key]) count = count + 1
            }
        })
    }

    return count
}

export function convertNormalPhoneNumber(number: string) {
    const first_three_character = number.substring(0, 3);

    if (first_three_character === "628") {
        number = number.replace(/^628/g, "08");
    }

    return number.replace(/[^\d]/, '');
}

export const unauthorizedErrorHandler = (type: 'refresh' | 'redirect', redirectTo?: string) => {
    if (process.browser) {
        switch (type) {
            case 'refresh':
                window.location.reload()
                break
            case 'redirect':
                if (redirectTo) window.location.href = redirectTo
                break
        }
    }
}

export const toastAxiosError = async (err: any) => {
    let errorMessage = err.response.data.message
    const toast = (await import('react-toastify')).toast
    if (err?.response?.status === 401) {
        unauthorizedErrorHandler('redirect', '/signin')
    } else {
        toast(errorMessage, {
            closeButton: false,
            type: 'error',
            autoClose: 3000,
            position: 'bottom-center',
            className: 'rounded mb-10 xs:px-2 md:px-8 text-center mx-auto',
            style: { color: '#fff' }
        })
    }
}

/**
 * Parse cookie string to JS object
 * @param cookie_string cookie string from req.headers.cookie
 */
export const parseCookie = (cookie_string: string): any => {
    try {
        return cookie_string
            .split(';')
            .map(v => v.split('='))
            .reduce((acc: any, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {})
    } catch {
        return {}
    }
}

export const useStorage = (callback: () => void, onError?: () => void) => {
    try {
        callback()
    } catch {
        // console.error('Cookies disabled')
        if (onError) onError()
    }
}

export const goScroll = (top: number) => {
    if ('scrollBehavior' in document.documentElement.style && window.scrollTo) {
        window.scrollTo({
            top,
            behavior: 'smooth',
        })
    } else {
        document.documentElement.scrollTop = top
    }
}

export const FBDataLayer = (url: string, title: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `fbPageview`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`
        });
    }
}

export const FBDataLayerViewContent = (url: string, title: string, project: string, content_id: string, content_category: string, content_name: string,
    value: string, city: string, region: string, property: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `ViewContent`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'lease_start_date': `2021-01-01`,
            'lease_end_date': `2100-01-01`,
            'project_name': `${project}`,
            'content_id': `${content_id}`,
            'content_category': `${content_category}`,
            'content_name': `${content_name}`,
            'content_type': `product`,
            'currency': `IDR`,
            'value': `${value}`,
            'city': `${city}`,
            'region': `${region}`,
            'country': `Indonesia`,
            'property_type': `${property}`
        });
    }
}

export const FBDataLayerDEM = (url: string, title: string, project: string, content_id: string, content_category: string, content_name: string,
    value: string, city: string, region: string, property: string, contact_method: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'InitiateCheckout',
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'project_name': `${project}`,
            'content_id': `${content_id}`,
            'content_category': `${content_category}`,
            'content_name': `${content_name}`,
            'content_type': `product`,
            'currency': `IDR`,
            'num_items': `1`,
            'value': `${value}`,
            'city': `${city}`,
            'region': `${region}`,
            'country': `Indonesia`,
            'property_type': `${property}`,
            'contact_method': `${contact_method}`
        });
    }
}

export const FBDataLayerSubmitted = (url: string, title: string, project: string, content_id: string, content_category: string, content_name: string,
    value: string, city: string, region: string, property: string, contact_method: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `Purchase`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'lease_start_date': `2021-01-01`,
            'lease_end_date': `2100-01-01`,
            'project_name': `${project}`,
            'content_id': `${content_id}`,
            'content_category': `${content_category}`,
            'content_name': `${content_name}`,
            'content_type': `product`,
            'currency': `IDR`,
            'num_items': `1`,
            'value': `${value}`,
            'city': `${city}`,
            'region': `${region}`,
            'country': `Indonesia`,
            'property_type': `${property}`,
            'contact_method': `${contact_method}`
        });
    }
}

export const FBDataLayerKPR = (url: string, title: string, price: number, dp: string, dpPercent: string, tenure: string, loan_amount: string, interest: string,
    content_id: string, content_category: string, content_name: string,) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'kpr_calculator',
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'price': `${price}`,
            'down_payment': `${dp.toString().replace(/[.,\s]/g, '')}`,
            'down_payment_percent': `${dpPercent}`,
            'tenure': `${tenure.toString().replace(/[.,\s]/g, '')}`,
            'loan_amount': `${loan_amount}`,
            'interest': `${interest}`,
            'content_id': `${content_id}`,
            'content_category': `${content_category}`,
            'content_name': `${content_name}`,
        });
    }
}

export const FBDataLayerSearch = (url: string, title: string, content_id: string, content_category: string, search: string | undefined,
    value: string, city: string, region: string) => {
    if (typeof (window) !== 'undefined') {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': `Search`,
            'page_url': `${window.location.href}`,
            'page_title': `${title}`,
            'content_id': `${content_id}`,
            'content_category': `${content_category}`,
            'content_type': `product`,
            'currency': `IDR`,
            'search_string': `${search}`,
            'value': `${value}`,
            'city': `${city}`,
            'region': `${region}`,
            'country': `Indonesia`
        });
    }
}

export const escapeRegex = (pattern: string) => pattern.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

export const fetchEncode = (url: string, init?: RequestInit | undefined): Promise<Response> => {
    return fetch(encodeURI(url), init)
}

export const serializeToQuery = (obj: { [key: string]: string | null }) => {
    let str = []
    for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== null) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent((obj[p] as string)))
        }
    return str.join("&")
}

export const download = (url: string, fileName: string) => {
    DefaultAxios.get(url, {
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(err => {
            unauthorizedErrorHandler(err);
        })
}

export const openLink = (url: string, isNewTab?: boolean) => {
    const link = document.createElement('a')
    link.href = url
    if (isNewTab) {
        link.setAttribute('target', `_blank`)
        link.setAttribute('rel', `noopener norefferrer`)
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const prepareContentShort = (text: string) => {
    return text.replace(/&nbsp;/g, '')
}

export const handleErrorPage = (context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>) => {
    return {
        notFound: true,
        props: {},
    }
}

export const replaceUrlParam = (url: string, data: any) => {
    let newUrl = url.replace('fh=1', '').replace('&&', '&').replace('?&', '&');
    for (let [paramName, paramValue] of Object.entries(data)) {
        let pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)')
        if (paramValue == null || paramValue == '') {
            paramValue = ''
            newUrl = newUrl.replace(pattern, '').replace(/[&#]$/, '')
        } else if (newUrl.search(pattern) >= 0) {
            newUrl = newUrl.replace(pattern, '$1' + paramValue + '$2')
        } else {
            newUrl = newUrl.replace(/[?#]$/, '')
            newUrl = newUrl + (newUrl.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
        }
    }
    if (newUrl[newUrl.length - 1] === '?') newUrl = newUrl.slice(0, newUrl.length - 1)
    window.history.pushState(null, '', newUrl);
}

export const setBrowserId = () => {
    const { v4: uuidv4 } = require('uuid');
    const browserId = _getCookie('browser_id')

    if (!browserId) {
        _setCookie('browser_id', uuidv4())
    }
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const handleScroll = (offsetTop: number, behavior?: ScrollBehavior | undefined) => {
    var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
    if (isSmoothScrollSupported) {
        window.scrollTo({
            top: offsetTop,
            behavior: behavior || 'smooth',
        });
    } else {
        document.documentElement.scrollTop = offsetTop
    }
}

export const isFloat = (num: number) => num % 1 ? true : false

export const snakeCase = (text: string) => text.split(' ').map(word => word.toLowerCase()).join('_')

export const getIsIOS = () => [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    // Old detection
    || navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1