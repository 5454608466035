import * as React from "react"
import { SVGProps } from "react"

const SlimArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.556 6 12 3.5m0 0L9.556 1M12 3.5H1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SlimArrow
