import Axios from 'axios';

const DefaultAxios = Axios;

// DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
DefaultAxios.interceptors.request.use(config => {
    if (!config.headers.Authorization) {
        const token = localStorage.getItem("token")

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
    }
    return config
})

DefaultAxios.interceptors.response.use(response => {
    return response;
}, error => {
        // const isLogin = error.response.config.url.match(/login/);
        // if (+error.response.status === 401 && !isLogin) { // Invalid token
        //     localStorage.removeItem('token');
        //     window.location.href = '/';
        // } else if (+error.response.status === 440) { // Expired token, try to refresh first
        //     if (!sessionStorage.getItem('prevent_multiple_refresh')) {
        //         sessionStorage.setItem('prevent_multiple_refresh', '1');
        //         return DefaultAxios
        //             .post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/refresh`, null, {
        //                 headers: {
        //                     Authorization: `Bearer ${localStorage.getItem('token')}`
        //                 }
        //             })
        //             .then(res => {
        //                 localStorage.setItem('token', res.data);
        //                 error.config.headers['Authorization'] = `Bearer ${res.data}`
        //                 DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data}`;
    
        //                 return DefaultAxios.request(error.config);
        //             })
        //             .finally(() => {
        //                 sessionStorage.removeItem('prevent_multiple_refresh');
        //             })
        //     } else {
        //         error.config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        //         return DefaultAxios.request(error.config);
        //     }
        // } else if (+error.response.status === 403) {
        //     window.location.href = '/';
        // }
        return Promise.reject(error);
})

export default DefaultAxios;
