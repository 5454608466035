import Axios from 'axios';
import { _storeLoginInfo } from './Cookie';

const AgentAxios = Axios.create();

// AgentAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
AgentAxios.interceptors.request.use(config => {
    if (!config.headers.Authorization) {
        try {
            const token = localStorage.getItem("token")
    
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
        } catch {
            // console.error('Cookies disabled')
        }
    }
    return config
})

AgentAxios.interceptors.response.use(response => {
    return response;
}, error => {
        const ignore = error?.response?.config?.url.match(/(login|recover|reset)/);
        if (error?.response?.status === 401 && !ignore) { // Invalid token
            try {
                localStorage.removeItem('token');
                // window.location.href = '/';
            } catch {
                // console.error('Cookies disabled')
            }
        } else if (error?.response?.status === 440) { // Expired token, try to refresh first
            try {
                if (!sessionStorage.getItem('prevent_multiple_refresh')) {
                    sessionStorage.setItem('prevent_multiple_refresh', '1');
                    return AgentAxios
                        .post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/refresh`, null, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(res => {
                            localStorage.setItem('token', res.data);
                            if (res.data) _storeLoginInfo(res.data, 3600)
                            error.config.headers['Authorization'] = `Bearer ${res.data}`
                            AgentAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data}`;
        
                            return AgentAxios.request(error.config);
                        })
                        .finally(() => {
                            sessionStorage.removeItem('prevent_multiple_refresh');
                        })
                } else {
                    error.config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                    return AgentAxios.request(error.config);
                }
            } catch {
                // console.error('Cookies disabled')
            }
        } else if (error?.response?.status === 403) {
            window.location.href = '/';
        }
        return Promise.reject(error);
})

export default AgentAxios;
