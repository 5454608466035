import React, { ReactNode, useImperativeHandle, useRef } from "react"

interface IProps {
    textArea?: boolean
    value?: string
    name?: string
    placeholder?: string
    label?: string | ReactNode
    error?: boolean
    errorText?: string
    onChange?: (e: any) => void
    onKeyDown?: (e: any) => void
    onBlur?: (e:any) => void
    leftIcon?: React.ReactNode
    leftFunction?: Function
    rightIcon?: React.ReactNode
    rightFunction?: Function
    isRightHide?: boolean
    classes?: string
    style?: Object
    notRounded?: boolean
    /** must include border-* */
    borderColor?: string
    helper?: string
    select?: boolean
    children?: ReactNode
    /** it will override all default padding size */
    padding?: string
    disabled?: boolean
    inputProps?: Partial<React.HTMLProps<HTMLInputElement>>
    textAreaProps?: Partial<React.HTMLProps<HTMLTextAreaElement>>
    rounded?: string
    fontSize?: string
    inputMode?: "text" | "search" | "none" | "tel" | "url" | "email" | "numeric" | "decimal"
}

export interface IOutlinedInput {
    focus: () => void
    blur: () => void
}

const OutlinedInput = React.forwardRef(({ textArea, value, name, placeholder, label, error, errorText, onChange, onKeyDown, onBlur, leftIcon, leftFunction, rightIcon, rightFunction, isRightHide, classes, style, notRounded, borderColor, helper, select, children, padding, disabled, inputProps, rounded, fontSize, textAreaProps, inputMode }: IProps, ref: React.Ref<IOutlinedInput>) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current?.focus()
        },
        blur: () => {
            inputRef.current?.blur()
        },
    }))

    return (
        <>
            {
                label ?
                <label className={`block pb-2 ${ error && 'text-error' }`}>{ label }</label>
                : null
            }
            <div className={`flex flex-row items-center ${disabled ? 'bg-gray-ee' : 'bg-white'} ${ classes || ` ${!notRounded && rounded || 'rounded-md'} ${error ? 'border-error' : borderColor || ''} ${padding || 'py-2 px-3'}` }`} style={style}>
                {
                    leftIcon ?
                    <span onClick={() => leftFunction ? leftFunction() : null} className="flex items-center justify-center">
                        { leftIcon }
                    </span>
                    : null
                }
                {
                    textArea ?
                    <textarea
                        placeholder={ placeholder || '' }
                        value={ value }
                        name={ name }
                        onChange={ onChange }
                        onKeyDown={ onKeyDown }
                        onBlur={ onBlur }
                        className={`w-full ${disabled ? 'bg-gray-ee' : 'bg-white'} ${disabled ? 'cursor-not-allowed' : ''} ${fontSize || 'text-sm'} outline-none ${ error && 'text-error' } ${ leftIcon ? 'pl-3' : '' } ${ rightIcon ? 'pr-3' : '' }`}
                        disabled={!!disabled}
                        {...textAreaProps}
                    />
                    : select ?
                    <select
                        placeholder={ placeholder || '' }
                        value={ value }
                        name={ name }
                        onChange={ onChange }
                        onKeyDown={ onKeyDown }
                        onBlur={ onBlur }
                        className={`w-full ${disabled ? 'bg-gray-ee' : 'bg-white'} ${disabled ? 'cursor-not-allowed' : ''} ${fontSize || 'text-sm'} outline-none ${ error && 'text-error' } ${ leftIcon ? 'pl-3' : '' } ${ rightIcon ? 'pr-3' : '' }`}
                        disabled={!!disabled}
                    >
                        { children }
                    </select>
                    :
                    <input
                        ref={inputRef}
                        inputMode={inputMode}
                        type="text"
                        placeholder={ placeholder || '' }
                        value={ value }
                        name={ name }
                        onChange={ onChange }
                        onKeyDown={ onKeyDown }
                        onBlur={ onBlur }
                        className={`w-full ${disabled ? 'bg-gray-ee' : 'bg-white'} ${disabled ? 'cursor-not-allowed' : ''} ${fontSize || 'text-sm'} outline-none ${ error && 'text-error' } ${ leftIcon ? 'pl-3' : '' } ${ rightIcon ? 'pr-3' : '' }`}
                        disabled={!!disabled}
                        {...inputProps}
                    />
                }
                {
                    rightIcon ?
                    <span
                        onClick={() => rightFunction ? rightFunction() : null}
                        className={`${ isRightHide ? 'invisible' : '' }`}
                    >
                        { rightIcon }
                    </span>
                    : null
                }
            </div>
            {
                (error && errorText) ?
                <p className="text-sm text-error ml-1">{ errorText }</p>
                : !!helper ?
                <p className="text-sm ml-1">{ helper }</p>
                : null
            }
        </>
    )
})

export default OutlinedInput