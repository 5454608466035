import * as React from "react"
import { SVGProps } from "react"

const ButtonOverlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.995 18.007a1 1 0 0 1-1.002.998l-4.182-.007a3 3 0 0 1-2.995-3.005l.004-2.182a3 3 0 0 1 3.005-2.995l2.182.004a3 3 0 0 1 2.995 3.005l-.007 4.182ZM1 19a1 1 0 0 1-1-1v-4.182a3 3 0 0 1 3-3h2.182a3 3 0 0 1 3 3V16a3 3 0 0 1-3 3H1ZM0 2a1 1 0 0 1 1-1h4.182a3 3 0 0 1 3 3v2.182a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V2ZM16.993.995a1 1 0 0 1 1.002.998l.007 4.182a3 3 0 0 1-2.995 3.005l-2.182.004A3 3 0 0 1 9.82 6.189l-.004-2.182a3 3 0 0 1 2.995-3.005l4.182-.007Z"
      fill="currentColor"
    />
  </svg>
)

export default ButtonOverlayIcon
