import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import SlimArrow from '../../icons/SlimArrow'
import LegacyCarousel from '../../LegacyCarousel'
import classes from './UnitCompareBanner.module.css'

interface UnitCompareBannerProps {
    isMobile: boolean
    onUnitCompareTutorialBannerClose: () => void
}

const UnitCompareBanner = (props: UnitCompareBannerProps) => {
    const renderUnitCompareBannerContent = (next: () => void, prev: () => void) => {
        const children: ReactNode[] = []

        children.push(
            <div className="w-full flex-shrink-0 flex flex-col items-center">
                <div className="w-236px h-236px md:w-261px md:h-261px rounded-full bg-red-#FFE0E2 flex items-center justify-center relative">
                    <img src="/static/images/unit-compare/unit-compare-desktop-1.png" alt="desktop 1" className='hidden md:block absolute left-1/2 top-1/2' style={{ height: 215, width: 'auto', transform: 'translate(calc(-50% - 6px), calc(-50% + 6.5px))' }} />
                    <img src="/static/images/unit-compare/unit-compare-desktop-1-extra.png" alt="desktop 1 extra" className='hidden md:block absolute right-0 bottom-0' style={{ height: 46, width: 'auto', transform: 'translate(-17px, -12px)' }} />
                    <img src="/static/images/unit-compare/unit-compare-desktop-1.png" alt="desktop 1" className='md:hidden absolute left-1/2 top-1/2' style={{ height: 194, width: 'auto', transform: 'translate(calc(-50% - 6px), calc(-50% + 6.5px))' }} />
                    <img src="/static/images/unit-compare/unit-compare-desktop-1-extra.png" alt="desktop 1 extra" className='md:hidden absolute right-0 bottom-0' style={{ height: 36, width: 'auto', transform: 'translate(-17px, -12px)' }} />
                </div>
                <p className='text-black-#232325 text-xl md:text-2xl font-black text-center mt-3 md:mt-2'>
                    Unit Comparing
                </p>
                <p className='text-black-#232325 text-xs md:text-base font-medium text-center mb-5 md:mb-4 mt-1 md:mt-0'>
                    You can compare up to 3 property-units by<br/> pressing the 'compare-button'
                </p>
                <button onClick={next} className="hidden border border-main h-10 md:flex items-center justify-center px-5 focus:outline-none rounded mb-5">
                    <span className='text-main text-13px font-bold'>
                        Next
                    </span>
                    <span className='ml-2'>
                        <SlimArrow className='text-main' />
                    </span>
                </button>
                <div className="flex md:hidden items-center justify-center mb-6">
                    <span className='text-main text-sm font-bold'>
                        Swipe
                    </span>
                    <span className='ml-2'>
                        <SlimArrow className='text-main' />
                    </span>
                </div>
            </div>
        )
        
        children.push(
            <div className="w-full flex-shrink-0 flex flex-col items-center">
                <div className="w-236px h-236px md:w-261px md:h-261px rounded-full bg-red-#FFE0E2 flex items-center justify-center relative">
                    <img src="/static/images/unit-compare/unit-compare-desktop-2.png" alt="desktop 1" className='hidden md:block absolute left-1/2 top-1/2' style={{ height: 216, width: 'auto', transform: 'translate(calc(-50% - 22px), calc(-50% + 17.5px))' }} />
                    <img src="/static/images/unit-compare/unit-compare-desktop-2.png" alt="desktop 1" className='md:hidden absolute left-1/2 top-1/2' style={{ height: 165, width: 'auto', transform: 'translate(calc(-50% - 22px), calc(-50% + 17.5px))' }} />
                </div>
                <p className='text-black-#232325 text-xl md:text-2xl font-black text-center mt-3 md:mt-2'>
                    Multi-Menu
                </p>
                <p className='text-black-#232325 text-xs md:text-base font-medium text-center  mb-5 md:mb-4 mt-1 md:mt-0'>
                    After clicking the 'compare-button', a list of <br/>items to be compared will appear here
                </p>
                <button onClick={props.onUnitCompareTutorialBannerClose} className="hidden border border-main h-10 md:flex items-center justify-center px-5 focus:outline-none rounded mb-5">
                    <span className='text-main text-13px font-bold'>
                        Ok, I understand
                    </span>
                </button>
                <div className="flex md:hidden items-center justify-center mb-6">
                    <span className='text-main text-sm font-bold'>
                        Swipe
                    </span>
                    <span className='ml-2'>
                        <SlimArrow className='text-main' />
                    </span>
                </div>
            </div>
        )

        if (props.isMobile) {
            children.push(
                <div className="w-full flex-shrink-0 flex flex-col items-center">
                    <div className="w-236px h-236px md:w-261px md:h-261px rounded-full bg-red-#FFE0E2 flex items-center justify-center relative">
                        <img src="/static/images/unit-compare/unit-compare-mobile-3.png" alt="desktop 1" className='block absolute left-1/2 top-1/2' style={{ height: 156, width: 'auto', transform: 'translate(calc(-50% - 5px), calc(-50% + 5px))' }} />
                        <img src="/static/images/unit-compare/unit-compare-mobile-3-extra.png" alt="desktop 1 extra" className='block absolute left-1/2 top-1/2 z-1' style={{ height: 53, width: 'auto', transform: 'translate(calc(-50% - -14px), calc(50% + 18px))' }} />
                    </div>
                    <p className='text-black-#232325 text-xl md:text-2xl font-black text-center mt-3 md:mt-2'>
                        Swipeable table
                    </p>
                    <p className='text-black-#232325 text-xs md:text-base font-medium text-center  mb-5 md:mb-4 mt-1 md:mt-0'>
                        You can compare 3 units at once.
                    </p>
                    <button onClick={props.onUnitCompareTutorialBannerClose} className="border border-main h-10 flex items-center justify-center px-5 focus:outline-none rounded mb-5">
                        <span className='text-main text-13px font-bold'>
                            Ok, I understand
                        </span>
                    </button>
                </div>
            )
        }

        return children
    }

    return (
        <div className='fixed flex justify-center items-center right-0 left-0 h-screen transition-all duration-150 ease-linear' style={{ zIndex: 999, backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <div className='w-full bg-white rounded-lg p-6 w-full max-w-unit-compare-mobile-banner md:max-w-554px relative'>
                <FontAwesomeIcon icon="times" width="0" className="cursor-pointer text-main mb-2 absolute right-0 top-0 m-4" onClick={props.onUnitCompareTutorialBannerClose} />
                <LegacyCarousel
                    show={1}
                    withIndicator
                    withoutArrow
                    renderChildren={renderUnitCompareBannerContent}
                    indicatorClasses={{
                        active: classes.active,
                        passive: classes.passive,
                        far: classes.passive,
                    }}
                >
                </LegacyCarousel>
            </div>
        </div>
    )
}

export default UnitCompareBanner
