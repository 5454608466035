import * as React from "react"
import { SVGProps } from "react"

const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 8.25v4.5m3-4.5v4.5M3 5.25h12m-.75 0-.65 9.107a1.5 1.5 0 0 1-1.496 1.393H5.897A1.5 1.5 0 0 1 4.4 14.357L3.75 5.25h10.5Zm-3 0V3a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v2.25h4.5Z"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TrashIcon
