import * as React from "react"
import { SVGProps } from "react"

const CompareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.487 14.095H.925a.92.92 0 0 0-.925.91c0 .5.416.91.925.91h5.562v1.629c0 .41.5.61.787.318l2.572-2.539a.462.462 0 0 0 0-.646l-2.572-2.54c-.287-.29-.787-.081-.787.32v1.637Zm10.026 3.44v-1.63h5.562a.92.92 0 0 0 .925-.91.92.92 0 0 0-.925-.91h-5.562v-1.629c0-.41-.5-.61-.787-.318l-2.572 2.539a.462.462 0 0 0 0 .646l2.572 2.54c.287.281.787.081.787-.328Z"
      fill="currentColor"
    />
    <path d="M11 1v27" stroke="currentColor" strokeLinecap="round" />
  </svg>
)

export default CompareIcon
