import React, { useEffect, useState } from 'react'
import { useUnitCompare } from '../../lib/providers/UnitCompareProvider'
import FeedbackCustomer from '../cards/FeedbackCustomer'
import UnitCompareCard from '../cards/UnitCompareCard'
import ButtonOverlayIcon from '../icons/ButtonOverlayIcon'

interface ButtonOverlayProps {
    withoutFeedback?: boolean
    feedbackMobileExtraMargin?: boolean
}

const ButtonOverlay = (props: ButtonOverlayProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [parentTrigger, setParentTrigger] = useState(0)
    const { units, addedCounter } = useUnitCompare()

    useEffect(() => {
        if (addedCounter) {
            setIsOpen(true)
            setParentTrigger(prev =>  prev + 1)
        }
    }, [addedCounter])

    return (
        <div className={`fixed right-0 bottom-0 z-30 ${props.feedbackMobileExtraMargin ? 'mb-20 md:mb-10' : 'mb-5 md:mb-10'} mr-4 md:mr-8 flex flex-col items-end pointer-events-none`}>
            { units.length > 0 && <UnitCompareCard isOpen={isOpen} parentTrigger={parentTrigger} /> }
            {
                !props.withoutFeedback &&
                <FeedbackCustomer
                    feedbackMobileExtraMargin={props.feedbackMobileExtraMargin}
                    isOpen={isOpen}
                />
            }
            <div
                onClick={() => setIsOpen(prev => !prev)}
                className={`relative w-46px h-46px pointer-events-auto ${isOpen ? 'bg-white' : 'bg-main'} border border-main rounded-full transition-all duration-200 ease-linear cursor-pointer flex items-center justify-center`}
            >
                {
                    (units.length > 0 && !isOpen) &&
                    <span className='absolute top-0 right-0 mb-2 ml-2 w-14px h-14px text-2xs leading-none text-white rounded-full bg-yellow-#FFC700 font-semibold flex items-center justify-center'>
                        {units.length}
                    </span>
                }
                <ButtonOverlayIcon className={`text-lg ${isOpen ? 'text-main' : 'text-white'} transition-all duration-200 ease-linear`} />
            </div>
        </div>
    )
}

export default ButtonOverlay
